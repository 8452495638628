import React from 'react';

import { isEmpty } from '@dop/shared/helpers/immutableHelpers';
import { toObject } from '@dop/shared/helpers/immutableHelpersWithoutArgs';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import {
	mqWidth1120px,
	mqWidth640px,
} from '@dop/ui-primitives/base/mediaQueryDefinitions';
import { Grid } from '@dop/ui-primitives/layout/Grid';
import { Heading } from '@dop/ui-composites/typography/Heading';

import { PageBlockAnkeiler } from './pageBlockAnkeiler';
import { oranje } from '../../../styles/colors';

export const PageBlocks = ({ title, pageBlocks, showIcon }) => {
	if (isEmpty(pageBlocks)) {
		return null;
	}

	return (
		<Box
			$padding={[1.5, 0, 0]}
			$borderBlockStart={['1px', 'solid', oranje.default]}
		>
			<Stack $gap={3}>
				<Heading level={2} $color={oranje.default}>
					{title}
				</Heading>
				<Grid
					$gap={[1.5, 3]}
					$columns="1fr"
					$mq={{
						[mqWidth640px.min]: { $columns: 'repeat(2, 1fr)' },
						[mqWidth1120px.min]: {
							$columns: 'repeat(3, 1fr)',
						},
					}}
				>
					{pageBlocks.map((item, key) => {
						return (
							<PageBlockAnkeiler
								key={key}
								{...toObject(item)}
								showIcon={showIcon}
							/>
						);
					})}
				</Grid>
			</Stack>
		</Box>
	);
};
