'use client';

import { pushCookieEvent } from '@/globals/analytics/events/pushCookieEvent';
import {
	createLocalStorageStringStore,
	useLocalStorageStore,
} from '@/globals/webStorage/useLocalStorage';
import {
	PropsWithChildren,
	createContext,
	useCallback,
	useContext,
	useMemo,
} from 'react';

export const cookieConsentStates = [
	'unknown',
	'block-external',
	'all',
] as const;
export type CookieConsentState = typeof cookieConsentStates[number];
export type CookieWidget =
	| 'cookiebar-select'
	| 'videobar-select'
	| 'settingsbar-select'
	| 'localstorage-select';

const cookieConsentContext = createContext<
	| [
			CookieConsentState | null,
			(state: CookieConsentState, widget: CookieWidget) => void
	  ]
	| undefined
>(undefined);

const store = createLocalStorageStringStore(
	'acceptCookies',
	cookieConsentStates
);

export const CookieConsentProvider = ({ children }: PropsWithChildren) => {
	const [consentState, setConsentState] = useLocalStorageStore(
		store,
		'unknown'
	);

	const setCookieConsentState = useCallback(
		(state: CookieConsentState, widget: CookieWidget) => {
			setConsentState((oldState) => {
				pushCookieEvent({
					oldCookieConsent: oldState === null ? 'unknown' : oldState,
					cookieConsent: state,
					widget,
				});

				return state;
			});
		},
		[setConsentState]
	);

	return (
		<cookieConsentContext.Provider
			value={useMemo(
				() => [consentState, setCookieConsentState],
				[consentState, setCookieConsentState]
			)}
		>
			{children}
		</cookieConsentContext.Provider>
	);
};

export const useCookieConsent = () => {
	const cookieConsentContextValue = useContext(cookieConsentContext);

	if (cookieConsentContextValue == null)
		throw new Error('No cookie consent context found');

	return cookieConsentContextValue;
};
