import { SvgContent } from '@dop/ui-primitives/media/Svg';

export const IconPlayButtonHome: SvgContent = ({
	cropped = false,
	...props
}) => (
	<svg
		{...(cropped
			? { viewBox: '0 0 64 64', width: '64', height: '64' }
			: { viewBox: '0 0 64 64', width: '64', height: '64' })}
		{...props}
	>
		<g fill="none" fillRule="evenodd">
			<circle cx="32" cy="32" r="32" fill="#FFF" fillOpacity="0.8" />
			<circle cx="32" cy="32" r="25" fill="#02A6E8" />
			<polygon fill="#FFF" points="27 21 44 32 27 43" />
		</g>
	</svg>
);
