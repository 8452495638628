import React from 'react';

import { Grid } from '@dop/ui-primitives/layout/Grid';
import { mqWidth880px } from '@dop/ui-primitives/base/mediaQueryDefinitions';

import { columnSizes } from './pageLayout.styles';
import { SlotElement } from './pageLayout.types';

export type Props = {
	jumboSlot?: SlotElement;
	alertSlot?: SlotElement;
	mainSlot?: SlotElement;
};

/**
 * Layout grid for BGOV home page
 */
export const HomePageBGOVLayout = ({
	jumboSlot,
	alertSlot,
	mainSlot,
}: Props): React.ReactElement | null => {
	return (
		<Grid
			$columns={columnSizes.oneColumn.join(' ')}
			$areas={`
                ${jumboSlot ? '"jumbo jumbo jumbo"' : ''}
				${alertSlot ? '"alert alert alert"' : ''}
                ${mainSlot ? '"side-space-start main side-space-end"' : ''}
            `}
			$mq={{
				[mqWidth880px.min]: {
					$columns: columnSizes.oneColumn.join(' '),
					$areas: `
					${jumboSlot ? '". jumbo jumbo"' : ''}
					${alertSlot ? '"alert alert alert"' : ''}
					${mainSlot ? '"side-space-start main side-space-end"' : ''}
					`,
				},
			}}
		>
			{jumboSlot && React.cloneElement(jumboSlot, { $gridArea: 'jumbo' })}
			{alertSlot && React.cloneElement(alertSlot, { $gridArea: 'alert' })}
			{mainSlot && React.cloneElement(mainSlot, { $gridArea: 'main' })}
		</Grid>
	);
};
