import styled from 'styled-components';

import { createUnderliner } from '@dop/shared/components/link/blockElements';

import { Link } from './link';

export const BlockLink = styled(Link).attrs(() => ({ checkExternal: false }))`
	display: block;
	border: none;
`;

BlockLink.Underliner = createUnderliner(BlockLink);
