import { useSiteDataSelector } from '@/globals/dataProviders/useSiteDataSelector';
import React from 'react';
import styled from 'styled-components';

import { addTestSelector } from '@dop/shared/helpers/testSelector';
import { IconPlayButtonHome } from '@dop/ui-icons/svg/playButtonHome';
import {
	mq,
	large,
	medium,
	extraSmall,
} from '@dop/shared/styleHelpers/mediaQueries';
import { getJSIn } from '@dop/shared/helpers/immutableHelpers';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { Grid } from '@dop/ui-primitives/layout/Grid';
import { mqWidth880px } from '@dop/ui-primitives/base/mediaQueryDefinitions';
import { Shelf } from '@dop/ui-primitives/layout/Shelf';
import { toRootCap } from '@dop/shared/styleHelpers/toRootCap';
import {
	FilledBoxButton,
	FilledBoxText,
} from '@dop/ui-composites/link/FilledBoxLink';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { TextBlock } from '@dop/ui-primitives/typography/TextBlock';
import { ContentLinkInline } from '@dop/ui-composites/link/ContentLinkInline';
import { Accordion } from '@dop/shared/components/accordion/Accordion';
import { Svg } from '@dop/ui-primitives/media/Svg';
import { IconPlusThin } from '@dop/ui-icons/svg/plusThin';
import { ButtonBlock } from '@dop/ui-primitives/interaction/InteractionBlock';
import { translate } from '@dop/shared/translate/translate';
import { Collapse } from '@dop/shared/components/collapse/collapse';
import { Dialog } from '@dop/ui-primitives/positioning/Dialog';
import { IconSluiten } from '@dop/ui-icons/svg/sluiten';
import { LinkType } from '@/globals/webApiTypes/common.types';
import {
	ShelfLinkBlock,
	ShelfLinkIcon,
	ShelfLinkText,
} from '@dop/ui-composites/link/ShelfLink';
import { WithLinkTypeIcon } from '@dop/ui-composites/link/WithLinkTypeIcon';
import { IconChevronRight } from '@dop/ui-icons/svg/chevronRight';
import { Youtube } from '@dop/shared/youtube/youtube';

import { grijs, hemelblauw } from '../../../styles/colors';
import { TopTasks } from '../headers/topTasks';
import { Heading } from '../heading';
import { EmbedContainerOverlay } from '../../embedStyled';
import { useCookieConsent } from '@/sections/cookies/useCookieConsent';
import { usePageDataSelector } from '@/globals/dataProviders/usePageDataSelector';

const HomeHeading = styled(Heading)`
	color: ${hemelblauw.darker};
	flex: 1;
	margin-top: 1rem;
	&& {
		${mq({ to: extraSmall })`
			font-size: 1.1rem;
		`}
	}
`;

const YoutubeIcon = styled(IconPlayButtonHome)`
	width: 6rem;
	height: 6rem;
	cursor: pointer;
	transition: 0.5s transform ease;
	&:hover {
		transform: scale(1.2);
	}
`;

const CoverImage = styled.img`
	height: 15rem;
	width: 100%;
	object-fit: cover;
	max-width: none;
	@media ${mqWidth880px.min} {
		height: 100%;
		min-height: 15rem;
	}
`;

const AbsoluteContainer = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
`;

const PlayButtonContainer = styled(AbsoluteContainer)`
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
`;

const PlayButton = styled.button`
	border: none;
	margin: 0;
	padding: 0;
	background: none;
	display: block;
	pointer-events: auto;

	width: 3rem;
	${mq({ from: medium })`
		width: 4rem;
	`}

	${mq({ from: large })`
		width: auto;
	`}
`;

const Downloads = ({ videoSources }: { videoSources: Array<LinkType> }) => {
	const [expandedMap, setExpandItems] = React.useState(false);
	const htmlId = React.useId();

	const downloadItem = {
		id: 'donwload-files',
		header: translate('download files'),
		content: (
			<Stack $gap={1.5}>
				{videoSources.map(({ href, text }, i) => (
					<ShelfLinkBlock
						linkType="webinar-download-link"
						key={`${href}-${i}`}
						href={href}
					>
						<ShelfLinkIcon
							as={IconChevronRight}
							$fill={['negative', 'primary']}
						/>
						<ShelfLinkText $color={'white'}>
							<WithLinkTypeIcon href={href}>{text}</WithLinkTypeIcon>
						</ShelfLinkText>
					</ShelfLinkBlock>
				))}
			</Stack>
		),
	};

	return (
		<Accordion items={[downloadItem]}>
			<Box>
				<ButtonBlock
					type="button"
					$inlineSize="100%"
					onClick={() => setExpandItems((expandedMap) => !expandedMap)}
					aria-expanded={expandedMap}
					aria-controls={`accordion-item-content-${htmlId}`}
					id={`accordion-item-title-${htmlId}`}
					{...addTestSelector(`uiAccordionItem-${htmlId}`)}
				>
					<Box $padding={-1.5}>
						<Shelf
							$gap={4}
							$justifyContent="space-between"
							$alignItems="center"
						>
							<TextBlock
								$hover={{ $textDecorationLine: 'underline' }}
								$color={'white'}
								$fontWeight={expandedMap ? 'bold' : 'normal'}
							>
								{downloadItem.header}
							</TextBlock>
							<Svg
								$blockSize={1.5}
								$inlineSize="auto"
								$fill={['negative', 'primary']}
								$rotate={expandedMap ? '135deg' : '0deg'}
								$transition="rotate 500ms"
								as={IconPlusThin}
							/>
						</Shelf>
					</Box>
				</ButtonBlock>
				<Collapse
					id={`accordion-item-content-${htmlId}`}
					aria-labelledby={`accordion-item-title-${htmlId}`}
					role="region"
					isOpened={expandedMap}
					{...addTestSelector(`uiAccordionContent-${htmlId}`)}
				>
					<Box $padding={[1.5, 0, 0.5]}>{downloadItem.content}</Box>
				</Collapse>
			</Box>
		</Accordion>
	);
};

const ModalComponent = ({
	onClose,
	isOpened,
	videoUrl,
	videoSources,
	startPlay,
}: {
	onClose: (event: Event | React.MouseEvent) => void;
	isOpened: boolean;
	videoUrl: string;
	videoSources: Array<LinkType>;
	startPlay: boolean;
}) => {
	const cookieAlertContent = useSiteDataSelector((site) => site.cookieAlert);
	const [consentState, setConsentState] = useCookieConsent();
	const showEmbed = consentState === 'all';

	const [allowResumePlaying, setAllowResumePlaying] = React.useState(true);

	return (
		<Dialog
			opened={isOpened}
			onClose={onClose}
			onCancel={onClose}
			$inlineSize="100%"
			$blockSize="min(100%, 100vw)"
			$maxBlockSize={`calc(100% - var(--root-cap) * 6)`}
			$maxInlineSize={`calc(100% - var(--root-cap) * 6)`}
		>
			<Box
				$backgroundColor={['positive', 'primary']}
				$blockSize="100%"
				$padding={[1.5, 1.5]}
			>
				<Stack $rows={`auto minmax(0, 1fr) auto`} $blockSize="100%" $gap={1.5}>
					<ButtonBlock
						$justifySelf="end"
						onClick={onClose}
						type="button"
						aria-label="Close video"
					>
						<Box $padding={-1.5}>
							<Svg
								as={IconSluiten}
								$fill={['negative', 'secondary']}
								$blockSize={1.5}
								$inlineSize={'auto'}
							/>
						</Box>
					</ButtonBlock>
					{showEmbed && isOpened ? (
						<Youtube
							data-focusable
							pausePlaying={!startPlay}
							allowResumePlaying={allowResumePlaying}
							setAllowResumePlaying={setAllowResumePlaying}
							src={videoUrl}
							width="100%"
							height="100%"
							id="video"
							autostart={true}
						/>
					) : (
						<Box
							$position="relative"
							$backgroundColor={grijs.light}
							$overflow="auto"
						>
							<EmbedContainerOverlay>
								<Stack $gap={2} {...addTestSelector('uiYoutubeCookieOverlay')}>
									<TextBlock>{cookieAlertContent.overlay}</TextBlock>
									<Shelf $gap={1} $alignItems="center">
										<FilledBoxButton
											onClick={() => {
												setConsentState('all', 'videobar-select');
											}}
											{...addTestSelector('uiYoutubeCookieAcceptButton')}
										>
											<FilledBoxText>
												{cookieAlertContent.consentButton.text}
											</FilledBoxText>
										</FilledBoxButton>
										<TextBlock>
											{` of `}
											<ContentLinkInline
												href={videoUrl}
												target="_blank"
												linkType="youtube_link"
											>
												{cookieAlertContent.youtube}
											</ContentLinkInline>
											{`.`}
										</TextBlock>
									</Shelf>
									<TextBlock>
										<ContentLinkInline
											linkType="cookieAlertOverlay"
											href={cookieAlertContent.link.href}
										>
											{cookieAlertContent.link.text}
										</ContentLinkInline>
									</TextBlock>
								</Stack>
							</EmbedContainerOverlay>
						</Box>
					)}
					<Downloads videoSources={videoSources} />
				</Stack>
			</Box>
		</Dialog>
	);
};

export const HomeTopTasks = ({ linkList }: { linkList: Array<[]> }) => {
	const containerRef = React.useRef(null);
	const playButtonFocusRef = React.useRef(null);
	const [showModal, setShowModal] = React.useState(false);

	const x = usePageDataSelector(getJSIn(['header']));
	const { image, videoSources, videoUrl } = x;
	return (
		<Box>
			<Grid
				$columns="auto"
				$autoRows="minmax(min-content, max-content)"
				$mq={{
					[mqWidth880px.min]: { $columns: '2fr 1fr var(--layout-side-space)' },
				}}
			>
				<Box $position="relative">
					{image && (
						<CoverImage
							css={`
								grid-column: 1/-1;
							`}
							ref={containerRef}
							src={image.src}
							alt={image.alt}
						/>
					)}

					<Shelf
						$columns=" 1fr"
						css={`
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
							width: 100%;
						`}
					>
						<Box
							$position="relative"
							css={`
								@media ${mqWidth880px.max} {
									padding-inline: var(--layout-side-space);
								}
							`}
						>
							<HomeHeading
								level={3}
								styleLevel={2}
								css={`
									text-shadow: 0px 0px 10px rgba(255 255 255 / 70%);
								`}
							>
								<TextBlock $capSize={[1.5, 1.8]} $fontWeight="bold">
									{image.alt}
								</TextBlock>
							</HomeHeading>
							{videoUrl && (
								<PlayButtonContainer>
									<PlayButton
										id="youtubePlayButton"
										ref={playButtonFocusRef}
										aria-label="Play video"
										type="button"
										onClick={() => setShowModal(true)}
									>
										<YoutubeIcon />
									</PlayButton>
								</PlayButtonContainer>
							)}
						</Box>
					</Shelf>
				</Box>
				<Grid
					$areas={`"padding main"`}
					$columns="var(--layout-side-space) auto"
					$mq={{
						[mqWidth880px.min]: {
							$columns: `${toRootCap(2)} auto`,
						},
					}}
				>
					<Box $gridArea="main" $padding={[0, 0, 2]}>
						<TopTasks linkList={linkList} />
					</Box>
				</Grid>
			</Grid>
			<ModalComponent
				isOpened={showModal}
				onClose={() => {
					setShowModal(false);
				}}
				startPlay={showModal}
				videoUrl={videoUrl}
				videoSources={videoSources}
			/>
		</Box>
	);
};
