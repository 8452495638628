'use client';

import { Main } from '@dop/shared/components/layout/main';
import { Box } from '@dop/ui-primitives/planeDivision/Box';
import { HomePageBGOVLayout } from '@dop/shared/components/pageLayout/HomePageBGOVLayout';
import { globalLayoutTheme } from '@dop/shared/components/pageLayout/pageLayout.styles';
import { Stack } from '@dop/ui-primitives/layout/Stack';
import { Ruler } from '@dop/ui-primitives/planeDivision/Ruler';
import { headerFooterColorThemeBGOV } from '@dop/shared/footer/headerFooterColorThemeBGOV';
import { useMapObjectPropsToImmutable } from '@dop/shared/helpers/immutableHelpers';

import { HomeTopTasks } from '../display/home/homeTopTasks';
import { oranje } from '../../styles/colors';
import { MainLinkBlocks } from '../display/home/mainLinkBlocks';
import { PageBlocks } from '../general/pageblocks/pageBlocks';
import { CoronaBar, useCoronaInfo } from '../display/coronaBar';

const HomePage = (props) => {
	const immutablePageData = useMapObjectPropsToImmutable(props);
	const { header, topTasks, ankeilersLists, mainSubjects } = immutablePageData;

	const coronaInfo = useCoronaInfo();

	return (
		<Main $padding={[0, 0, 3, 0]}>
			<HomePageBGOVLayout
				alertSlot={
					coronaInfo != null ? (
						<CoronaBar />
					) : (
						<Ruler $borderWidth="1px" $borderColor={oranje.default} />
					)
				}
				jumboSlot={
					<Box as="header" $colorTheme={headerFooterColorThemeBGOV}>
						<HomeTopTasks {...header?.toObject()} {...topTasks?.toObject()} />
					</Box>
				}
				mainSlot={
					<Box $gridArea="main" $padding={[2, 0]}>
						<Stack $gap={globalLayoutTheme.rowGap}>
							<MainLinkBlocks linkList={mainSubjects} />

							{ankeilersLists.map((item) => {
								return (
									<PageBlocks
										key={item.get('title')}
										title={item.get('title')}
										pageBlocks={item.get('ankeilers')}
										showIcon={false}
										className="smallBottomMargin"
									/>
								);
							})}
						</Stack>
					</Box>
				}
			/>
		</Main>
	);
};

export default HomePage;
